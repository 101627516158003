@import "_styles/variables";
@import "_modules/VendorModule/_styles/Vendor.scss";

.passport-missing-info-container {
    padding: 20px 12px;
    h2 {
        margin-bottom: 20px;
        // color: $color-pri;
    }
    .booking-details {
        .key {
            font-weight: $font-weight-m;
        }
        .value {
            &.booking-no-value {
                word-break: break-all;
            }
            .small-text {
                font-size: 0.8em;
            }
        }
        table {
            width: auto;
            max-width: 100%;
            td {
                padding: 5px 12px;
                vertical-align: top;
            }
        }
    }
    .message-wrapper {
        max-width: 600px;
        margin: 0px auto;
        padding: 25px 20px;
        border-left: 4px solid;
        text-align: center;
        border-radius: 10px;
        box-shadow: 5px 5px 10px $color-box-shadow;
        margin-bottom: 20px;
        border-color: $color-tertiary-2;
        &.message-wrapper-2 {
            text-align: left;
        }
        &.error-msg {
            border-color: $color-error;
        }
        &.success-msg {
            border-color: $color-success;
        }
        .go-back-section {
            .button-wrapper {
                text-align: right;
            }
        }
        .message-header {
            font-size: 1.2em;
            font-weight: $font-weight-m;
        }
    }
}
