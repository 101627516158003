@import "_styles/variables";

$width-initial: 600px;
$width-data-container: 160px;
$height-content: 30px;
$height-btn-grp: 90px;

.bg-cover {
    z-index: 140;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: #0008;
    top: 0px;
    left: 0px;
}
.bp-viewer-container {
    z-index: 150;
    width: $width-initial;
    height: 100vh;
    position: fixed;
    top: 0px;
    right: 0px;
    padding: 10px 0px 10px 10px;
    margin: 0px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px $color-box-shadow;
    border-radius: 10px 0px 0px 10px;
    transition: 0.5s linear all;
    background-color: white;
    .header {
        height: $height-content;
        display: flex;
        gap: 10px;
    }
    .content {
        display: flex;
        gap: 10px;
        height: calc(100% - #{$height-content});
        .data-container {
            width: $width-data-container;
            height: 100%;
            .data-grp {
                // background-color: cornsilk;
                height: calc(100% - #{$height-btn-grp} - 10px);
                margin-bottom: 10px;
                overflow: auto;
                .data-wrapper {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    .data-key {
                        font-size: 0.9em;
                    }
                    .data-value {
                        padding-left: 5px;
                        font-weight: bold;
                        word-break: break-all;
                    }
                }
            }
            .btn-grp {
                // background-color: aquamarine;
                height: $height-btn-grp;
                display: flex;
                gap: 10px;
                flex-direction: column;
                justify-content: flex-end;
                button {
                    width: 100%;
                }
            }
        }
        .pdf-container {
            // background-color: mistyrose;
            width: calc(100% - #{$width-data-container});
            width: 100%;
            height: 100%;
        }
    }
}

.doc-viewer {
    z-index: 180;
    background: white;
    position: fixed;
    height: 100vh;
    width: $width-initial;
    top: 0px;
    right: calc(-1 * #{$width-initial});
    padding: 0px 0px;
    margin: 0px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px #0008;
    border-radius: 10px 0px 0px 10px;
    transition: 0.5s linear all;
    .cust-pdf-loader {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background: #fffa;
        z-index: 9999999;
        img {
            position: absolute;
            height: 80px;
            top: calc(50% - 40px);
            left: calc(50% - 40px);
            z-index: 9998;
        }
    }
    div[class^="col-"] {
        padding: 5px;
    }
    .close-feedback-btn {
        z-index: 185;
        position: absolute;
        top: 10px;
        left: 10px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
        outline: none;
        // background: $color-disabled-1;
        // &:hover {
        //     background: $color-disabled-2;
        // }
    }
    .pdf-link {
        padding-top: calc(10px + 2px);
        padding-left: calc(10px + 30px + 20px);
    }
    .data-col {
        position: relative;
        .selected-checkin-data {
            height: calc(100% - #{$height-btn-grp} - 20px);
            // background: green;
            overflow-y: auto;
            line-height: 1.2;
            font-size: 0.9em;
            .header {
                margin: 5px 0px 10px;
                font-weight: bold;
            }
            .data-wrapper {
                margin-top: 5px;
                .data-key {
                    font-weight: normal;
                }
                .data-value {
                    padding-left: 5px;
                    font-weight: bold;
                    word-break: break-all;
                    font-size: 1.2em;
                }
            }
        }
        .link-btn-wrapper {
            // background: red;
            z-index: 185;
            position: absolute;
            bottom: 20px;
            left: 10px;
            height: $height-btn-grp;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
}
