@import "_styles/variables";

.cust-grid-btn {
    outline: none;
    border: none;
    border-radius: $size-form-border-radius;
    // margin: 0px auto;
    color: white;
    font-weight: bold;
    &.cust-button-secondary {
        background-color: $color-tertiary-2;
    }
    &.cust-btn-success {
        background-color: $color-success;
    }
    &.cust-btn-error {
        background-color: $color-error;
    }
}
.detail-panel-container {
    $margin-tp: 5px;
    $margin-bt: 10px;
    $margin-rt: 5px;
    $margin-lt: 100px;
    width: calc(100% - #{$margin-rt} - #{$margin-lt});
    height: calc(100% - #{$margin-tp} - #{$margin-bt});
    margin: $margin-tp $margin-rt $margin-bt $margin-lt;
    border: 1px solid $color-border;
    border-radius: $size-form-border-radius;
    box-shadow: 5px 5px 10px $color-box-shadow;
    padding: 10px;
    .data {
        height: 100%;
        width: 100%;
        $ht: 32px;
        .text-content {
            height: $ht;
        }
        .grid-content {
            height: auto;
            max-height: calc(100% - #{$ht});
            overflow: auto;
        }
    }
    .no-data {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
