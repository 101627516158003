$color-primary: #e7c418;
$color-primary-hover: #e7c4185a;
$color-primary-fg48: #00a3d4;
$color-secondary: #161615;
$color-secondary-fg48: #808180;
$color-tertiary: white;
$color-tertiary-2: #d0d0d0;
$color-tertiary-3: #f5f4f4;
$color-tertiary-4: #6c85b1;
$color-tertiary-5: #4d4d4d;
$color-tertiary-6: #82d4bb;
$color-tertiary-7: #b0b0b0;
$color-font: #212529;
$color-link: #227afd;
$color-border: #e0e0e0;
$color-background-brightness: #0008;
$color-box-shadow: #0008;
$color-success: #28a745;
$color-error: #dc3545;
$color-pri: #007bff;

$size-container-large-width: 1800px;
$size-container-small-width: 800px;
$size-form-border-radius: 5px;

$font-size-xs: 0.8em;
$font-size-s: 0.9em;
$font-size-m: 1em;
$font-size-l: 1.1em;
$font-size-xl: 1.2em;

$font-weight-m: 600;

:root {
    --color-background-brightness: #{$color-background-brightness};
}

$size-content-top-vendor: 30px;
$size-content-bottom-vendor: 30px;
$size-header-height-sm-vendor: 65px;

$size-content-top-chk: 20px;
$size-content-bottom-chk: 20px;
$size-header-height-chk: 65px;
