@import "_styles/variables";

.file-selector-container {
    .file-selector-wrapper {
        .select-file-btn {
            .button-primary {
                display: inline-block;
                // line-height: 36px;
                // padding-top: 0px;
                // padding-bottom: 0px;
            }
            input {
                display: none;
            }
        }
        .filename {
            display: inline;
            margin-left: 10px;
            @media (max-width: 767px) {
                display: block;
                margin-top: 5px;
                margin-left: 0px;
            }
        }
    }
    .img-wrapper {
        max-width: 200px;
        max-height: 200px;
        height: 100%;
        width: 100%;
        // margin-left: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-border;
        border-radius: $size-form-border-radius;
        padding: 10px;
        margin-top: 10px;
        @media (max-width: 767px) {
            margin-top: 10px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}
