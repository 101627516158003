@import "_styles/variables";

.dashboard-container {
    h2 {
        margin-bottom: 20px;
    }
    .select-file-btn {
        span[class^="btn-"] {
            display: inline-block;
        }
        input[type="file"] {
            display: none;
        }
    }
    .flight-segments-container,
    .passengers-container {
        .item-wrapper {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.last-item-wrapper {
                margin-bottom: 0px;
            }
        }
    }
    .products-features-container {
        .item-wrapper {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.last-item-wrapper {
                margin-bottom: 0px;
            }
        }
        .cust-multi-select-dropdown {
            width: 100%;
            .dropdown-btn.dropdown-toggle {
                width: 100%;
                margin-bottom: 10px;
                // line-height: 1.5;
                height: 36px;
                font-size: $font-size-m;
                padding: 5px 15px;
                outline: none;
                box-shadow: none;
                border: 1px solid $color-border;
                border-radius: $size-form-border-radius;
                background-color: transparent;
                color: $color-font;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                position: relative;
                &:hover,
                &:focus {
                    border-color: $color-primary;
                }
                &::after {
                    display: none;
                }
                .chevron {
                    font-size: 0.8em;
                    position: absolute;
                    right: 4px;
                    bottom: 10px;
                }
            }
            .dropdown-menu.show {
                padding: 10px;
                min-width: 100%;
                .product-list,
                .feature-list {
                    font-size: 0.9em !important;
                    display: flex;
                    gap: 5px;
                    justify-content: flex-start;
                    align-items: center;
                }
                .feature-wrapper {
                    padding-left: 10px;
                }
            }
        }
        .ins-text {
            font-size: 0.6em;
        }
    }
    .channel-list {
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        align-items: center;
    }
}
.trip-parser-container {
    .trip-create-header {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: $font-size-xl;
        font-weight: $font-weight-m;
    }
}
.bulk-uploader-container {
    position: relative;
    .cust-loader {
        position: absolute;
        background: #fff2;
        img {
            position: absolute;
        }
    }
    .timer-container {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        .loader-img {
            height: 40px;
            width: 40px;
            min-width: 40px;
            img {
                height: 40px;
                width: 40px;
                min-width: 40px;
            }
        }
    }
}
.form-wrapper {
    padding: 20px 12px;
    border-radius: $size-form-border-radius;
    border: 1px solid $color-border;
    position: relative;
    &.margin-top {
        margin-top: 20px;
    }
    &.form-wrapper-1 {
        background-color: $color-tertiary-3;
    }
    &.form-wrapper-2 {
        background-color: #f9f9f9;
        padding: 10px 12px;
        margin-bottom: 10px !important;
    }
    .data-header {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
    }
    .btn-grp-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        &:not(.add-delete-btn-grp) {
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                .button-primary,
                .button-secondary {
                    min-width: 200px;
                    padding-left: 0px;
                    padding-right: 0px;
                    text-align: center;
                }
            }
        }
        &.align-bottom {
            align-items: flex-end;
            height: 100%;
            padding-bottom: 10px;
        }
    }
    .btn-grp-wrapper-2 {
        @extend .btn-grp-wrapper;
        justify-content: space-between;
        align-items: flex-start;
    }
    .cust-form-label {
        position: absolute;
        transform: translateY(-50%);
        top: 0px;
        left: 10px;
        padding: 0px 20px;
        background: $color-tertiary-3;
        border: 1px solid $color-border;
        border-radius: $size-form-border-radius;
        z-index: 10;
    }
}

.cust-text-error {
    margin-top: -5px;
    margin-bottom: 5px;
}

.custom-search-wrapper {
    width: 100%;
    min-height: 70px;
    height: auto;
}
.checkbox-options-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 2px 10px;
    min-height: 36px;
    height: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.primary-passenger-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    .icon {
        color: $color-pri;
        padding-bottom: 2.5px;
    }
}
.custom-grid-toolbar-btn {
    color: #1976d2;
    font-size: $font-size-s;
}
.custom-modal-grid {
    &.MuiGrid-container {
        margin-top: 5vh;
        height: 90vh;
        outline: none;
    }
}
.custom-modal-grid-2 {
    &.MuiGrid-container {
        height: auto;
    }
}

.fullname {
    font-size: 1.4em;
    line-height: 1.2;
    color: $color-pri;
    font-weight: $font-weight-m;
    padding-bottom: 10px;
}

.trip-create-form-container {
    .booking-id-info {
        color: $color-primary;
        margin-left: 5px;
        cursor: pointer;
        svg {
            width: 20px;
            aspect-ratio: 1;
        }
    }
}
