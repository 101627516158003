@import "_styles/variables";

$pad-val: 10px;

.simulate-trip-container {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // max-width: 500px;
    width: 100%;
    height: 100%;
    // margin: 10vh auto;
    padding: 0px $pad-val;
    .inner-wrapper {
        max-height: 100%;
        height: auto;
        width: 100%;
        overflow-y: auto;
        background-color: white;
        border-radius: $size-form-border-radius;
        box-shadow: 5px 5px 10px $color-box-shadow;
        padding: 20px 10px;
        .error-margin {
            margin-bottom: 10px;
        }
    }
    .header-data {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px $pad-val;
        border-bottom: 1px solid #dee2e6;
        margin: 0px calc(-1 * $pad-val) 20px;
        // margin-bottom: 20px;
        .close-modal {
            color: red;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .data-info-wrapper {
        padding: 10px 10px;
        margin-bottom: 10px;
        border-radius: $size-form-border-radius;
        border: 1px solid $color-border;
        table.data-info {
            width: 100%;
            td {
                vertical-align: top;
                &:first-child {
                    padding-right: 10px;
                    font-size: $font-size-s;
                    font-weight: $font-weight-m;
                    // opacity: 0.9;
                }
            }
        }
    }
    .checkbox-wrapper {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-left: 2px;
        margin-top: 10px;
    }
}
