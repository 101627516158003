.cust-loader {
    $size-loader: 80px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #fffa;
    z-index: 999999;
    img {
        position: fixed;
        height: $size-loader;
        width: $size-loader;
        top: calc(50% - #{$size-loader} / 2);
        left: calc(50% - #{$size-loader} / 2);
        z-index: 999998;
    }
}
