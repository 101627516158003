@import "_styles/variables";

$height-spacing: 20vh;

.custom-modal-container {
    position: absolute;
    top: $height-spacing;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px) !important;
    border-radius: $size-form-border-radius;
    overflow: hidden;
    background-color: white;
    box-shadow: 5px 5px 10px $color-box-shadow;
    &.MuiGrid-container {
        outline: none;
    }
    &.flight-dboard-weather-modal-card {
        .header-div{
            h3 {
                padding-top: 5px;
                font-size: 13px;
            }
        }
    }
    &.upcomingTripsWeather {
        .header-div{
            h3 {
                padding-top: 5px;
                font-size: 13px;
            }
        }
    }
    .header-div,
    .content-div {
        padding: 10px 10px;
    }
    .header-div {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid $color-border;
        h3 {
            margin: 0px;
        }
        .close-modal {
            color: red;
            cursor: pointer;
            &:hover {
                filter: brightness(80%);
            }
        }
    }
    .content-div {
        max-height: calc(100vh - 2 * #{$height-spacing});
        overflow-y: auto;
        width: 100%;
    }
}
.MuiBox-root {
    outline: none;
}
