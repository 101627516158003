@import "_styles/variables";

@font-face {
  font-family: "Mona Sans";
  src:
    url("../public/fonts/Mona-Sans.woff2") format("woff2 supports variations"),
    url("../public/fonts/Mona-Sans.woff2") format("woff2-variations");
  font-weight: 400 600 700;
  font-stretch: 125%;
}
@font-face {
  font-family: "Hubot Sans";
  src:
    url("../public/fonts/Hubot-Sans.woff2") format("woff2 supports variations"),
    url("../public/fonts/Hubot-Sans.woff2") format("woff2-variations");
  font-weight: 400 600 700;
  font-stretch: 100%;
}
@font-face {
  font-family: "Orbitron";
  src: url("../public/fonts/Orbitron-VariableFont_wght.ttf") format("truetype");
  font-weight: normal bold bolder;
}

* {
  box-sizing: border-box;
  scrollbar-width: thin;
}
body {
  margin: 0;
  font-family: "Mona Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-font;
  font-size: $font-size-m;
  @media (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
    font-size: $font-size-xs !important;
  }
}
#root {
  // height: 100vh;
  overflow: auto;
}

.full-height {
  height: 100% !important;
}
.full-width {
  width: 100% !important;
}
.no-margin {
  margin: 0px !important;
}
.no-padding {
  padding: 0px !important;
}

.text-error {
  color: $color-error;
  font-size: $font-size-s;
}
.text-transparent {
  color: transparent;
}
.text-xs {
  font-size: $font-size-xs !important;
}
.text-s {
  font-size: $font-size-s !important;
}
.text-m {
  font-size: $font-size-m !important;
}
.text-l {
  font-size: $font-size-l !important;
}
.text-xl {
  font-size: $font-size-xl !important;
}
.text-weight-m {
  font-weight: $font-weight-m;
}
.text-justify {
  text-align: justify;
}
.text-link {
  color: $color-link;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.container-content-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.container-small-width {
  max-width: $size-container-small-width;
  width: 100%;
  margin: 0px auto;
}
.container-large-width {
  max-width: $size-container-large-width;
  width: 100%;
  margin: 0px auto;
}

.cust-form-group {
  input:not([type="checkbox"]),
  textarea,
  select,
  .cust-checkbox {
    width: 100%;
    margin-bottom: 10px;
    // line-height: 1.5;
    font-size: $font-size-m;
    padding: 5px 15px;
    outline: none;
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: $size-form-border-radius;
    &:hover,
    &:focus {
      border-color: $color-primary;
    }
  }
  input:not([type="checkbox"]),
  select,
  .cust-checkbox {
    height: 36px;
  }
  .react-tel-input {
    input {
      margin-bottom: 10px !important;
    }
  }
  .text-error {
    margin-top: -5px;
    margin-bottom: 5px;
  }
  &.button-wrapper {
    margin-top: 15px;
    text-align: right;
  }
  &.button-wrapper-2 {
    margin-bottom: 10px;
    height: calc(100% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  // typeahead dropdown
  .rbt {
    &.typeahead {
      input {
        box-shadow: none;
      }
    }
    .rbt-menu.dropdown-menu {
      padding: 0px;
      border: 1px solid $color-primary;
      border-top-width: 5px;
      border-radius: $size-form-border-radius;
      .dropdown-item {
        padding: 5px 10px;
        border-bottom: 1px solid $color-primary;
        font-size: $font-size-m;
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: $font-size-xs !important;
        }
        @media (max-width: 575px) {
          font-size: $font-size-xs !important;
        }
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          color: $color-tertiary;
          background-color: $color-primary;
        }
      }
    }
  }
}
.cust-checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  input {
    margin-top: 4px;
    height: 16px;
    width: 16px;
  }
}

.button-primary,
.button-secondary {
  color: $color-tertiary;
  border-radius: $size-form-border-radius;
  padding: 5px 30px;
  border-width: 1px;
  border-style: solid;
}
.button-primary {
  background-color: $color-secondary;
  border-color: $color-primary;
  cursor: pointer;
  &:hover {
    background-color: $color-primary;
    border-color: $color-tertiary;
  }
}
.button-secondary {
  background-color: $color-primary;
  border-color: $color-tertiary;
  cursor: pointer;
  &:hover {
    background-color: $color-secondary;
    border-color: $color-primary;
  }
}

.container {
  .container-fluid {
    padding: 0px;
  }
}

.container,
.container-fluid {
  .row {
    .padding-top {
      padding-top: 20px;
    }
    .padding-top-small-screen {
      @media (max-width: 767px) {
        padding-top: 20px;
      }
    }
    .padding-top-large-screen {
      @media (min-width: 768px) {
        padding-top: 20px;
      }
    }
  }
}

// common
.header-content-container {
  color: $color-tertiary;
  background-color: $color-tertiary-5;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  h1 {
    line-height: 1.2;
    font-weight: bold;
  }
  .logo-wrapper {
    max-width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      margin: 0px auto;
    }
    img {
      width: 100%;
    }
  }
  p:last-child {
    @media (min-width: 768px) {
      margin: 0px;
    }
  }
}
.data-card-wrapper {
  $size-padding-side: 20px;
  $size-padding-bottom: 30px;

  height: 100%;
  border-radius: 10px;
  position: relative;
  padding: 20px $size-padding-side $size-padding-bottom;
  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    height: 40px;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .content-header {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .content-data {
    text-align: center;
    margin-bottom: calc(15px + 35px);
  }
  .link-btn {
    position: absolute;
    bottom: $size-padding-bottom;
    left: $size-padding-side;
    right: $size-padding-side;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.bordered-img-wrapper {
  display: flex;
  justify-content: center;
  img {
    border-radius: 20px;
    border: 5px solid $color-secondary;
    width: 100%;
    max-width: 450px;
    height: auto;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.cust-loader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: #fffa;
  z-index: 9999999;
  img {
    position: fixed;
    height: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    z-index: 9998;
  }
}

.cust-container-grid {
  box-shadow: 5px 5px 10px $color-box-shadow;
  &.padding-spacing10 {
    padding-right: 10px;
    padding-bottom: 10px;
  }
  &.padding-all {
    padding: 10px;
  }
  &.MuiGrid-container {
    margin: 0px;
    width: calc(100%);
    background-color: white;
    border-radius: $size-form-border-radius;
  }
  &.MuiGrid-container.center-aligned {
    margin: 0px auto;
  }
}
// mui grid button
.mui-grid-toolbar-btn {
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 4px 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .mui-icon {
    margin-right: 8px;
    margin-left: -2px;
    svg {
      height: 18px;
      aspect-ratio: 1;
    }
  }
}
.grid-msg {
  text-align: center;
  padding: 30px 0px;
  font-weight: $font-weight-m;
}
.grid-msg-success {
  @extend .grid-msg;
  color: $color-success;
}
.grid-msg-error {
  @extend .grid-msg;
  color: $color-error;
}

// hide arrows from input number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// time picker
// input[type="time"]::-webkit-calendar-picker-indicator {
//   pointer-events: none;
// }
input[type="date"],
input[type="time"] {
  position: relative;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    background: transparent;
  }
}

// react-phone-input-2
.react-tel-input {
  z-index: 11;
  input {
    height: 36px !important;
    width: 100% !important;
    box-shadow: none !important;
    border-color: $color-border !important;
    border-radius: $size-form-border-radius !important;
    padding-left: 48px !important;
    &:hover,
    &:focus {
      border-color: $color-primary !important;
    }
  }
  .flag-dropdown {
    background-color: $color-tertiary-3 !important;
  }
}

// mui x toolbar button
.custom-grid-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 4px 5px;
  border-radius: 4px;
  .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 8px;
    margin-left: -2px;
  }
}

// material ui
.data-grid {
  width: 100%;
  height: 350px;
  &.data-grid-normal {
    height: 400px;
  }
  &.data-grid-large {
    height: 600px;
  }
  // .success-grid [class$="MuiDataGrid-columnHeaderRow"] {
  //   color: white;
  //   font-weight: $font-weight-m;
  //   background-color: var(--success-light-color);
  // }
  // .failure-grid [class$="MuiDataGrid-columnHeaderRow"] {
  //   color: white;
  //   font-weight: $font-weight-m;
  //   background-color: var(--error-light-color);
  // }
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin-top: 1em;
  margin-bottom: 1em;
}

.gridLogo {
  height: 30px;
  width: 50px;
  overflow: hidden;
  position: relative;
  img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.gridlink {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.prefWrapper {
  padding: 10px 0;
  .prefChannel {
    padding-bottom: 5px;
    font-weight: bold;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .prefChannelBadge {
    padding: 5px 10px;
    display: inline-block;
    margin: 0 0px 0 10px;
    width: fit-content;
    background: #eee;
    border-radius: $size-form-border-radius;
    border: none;
    outline: none;
    // cursor: pointer;
    &:hover {
      background: #ddd;
    }
    &:disabled {
      background: #eee;
    }
  }
  .selectedPrefChannelBadge {
    font-weight: bold;
    color: #1976d2;
  }
}
