@import "_styles/variables";


.searchWrapper {
    width: 100%;
    // height: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: $size-form-border-radius;
    border: 1px solid $color-border;
    position: relative;
    justify-content: start;
    position: relative;

    &:active,
    &:focus {
        border: 1px solid $color-primary;
    }

    .searchPretext {
        height: 100%;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 30px;
            width: 30px;
            margin-right: 10px;
        }
    }

    .searchInput {
        height: 100%;
        padding: 15px 0;
        border: none;
        outline: none;

        &:focus,
        &:active {
            border: none;
            outline: none;
        }
    }

    .advancedBtn {
        height: 100%;
        width: 70px;
        padding: 0px 10px;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 20px;
            width: 20px;
        }

        &:hover {
            background: #eee;
        }
    }
}

.fullWidth {
    width: calc(100% - 70px);
}

.filterOnWidth {
    width: calc(100% - 70px - 70px);
}

.advanceSearchWrapper {
    margin-top: 20px;
    width: 100%;
    border-radius: $size-form-border-radius;
    border: 1px solid $color-border;
    padding: 20px;

    &.hide {
        display: none;
    }
}

.advancedSearchOnMsg {
    padding: 5px 0;
    color: red;
    font-size: 12px;
    float: right;
}

.cust-form-group {
    .btn-grp-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}