@import "_styles/variables";

.bp-uploader-container {
    .row {
        margin: 0px;
    }
    .upload-btn {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}
