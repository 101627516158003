@import "_styles/variables";

.layout-main-container {
    height: 100%;
    overflow-y: auto;
    .children-container {
        height: 100%;
        // background-color: lightseagreen;
    }
}
