@import "_styles/variables.scss";

.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffa;
    z-index: 9999999;
    .loadingGif {
        height: 75px;
        width: 75px;
    }
}
